export const links = [
  {
    id: 1,
    url: 'https://forms.gle/WWbDu3ne61Su3FNJA',
    text: 'Registration',
  },
  {
    id: 2,
    url: 'https://ocasys.rug.nl/2024-2025/catalog/course/WBCS045-05',
    text: 'Ocasys',
  },
  {
    id: 3,
    url: '#contact',
    text: 'Contact',
  },
];
